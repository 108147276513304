import * as React from 'react';
import moment from 'moment-timezone';
import classnames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from 'tss-react/mui';
import { Provider } from 'api/providers/queries';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import { CSSObject } from 'tss-react';
import { ProviderPaymentMonth } from '@bondvet/types/providers';

import styles from './MonthOverview.module.scss';

const LightTooltip = withStyles(
    Tooltip,
    (theme): Record<'root' | 'popper' | 'tooltip' | 'arrow', CSSObject> => ({
        root: {},
        popper: {},
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'var(--primary-color)',
            border: '1px solid var(--provider-payment-color-tooltip-border)',
            boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
            fontSize: 14,
            fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
            fontWeight: 500,
        },
        arrow: {
            color: theme.palette.common.white,
            '&::before': {
                border: '1px solid var(--provider-payment-color-tooltip-border)',
                backgroundColor: '#fff',
                boxSizing: 'border-box',
            },
        },
    }),
    { name: 'month-overview__light-tooltip' },
);

interface MonthOverviewProps {
    timezone: string;
    provider: Provider;
    providerPaymentMonth: ProviderPaymentMonth;
}

const MonthOverview: React.FunctionComponent<MonthOverviewProps> = ({
    timezone,
    provider,
    providerPaymentMonth,
}) => {
    const translate = useTranslate();

    const moneyFormatter = useMoneyFormatter();

    const renderedDays = React.useMemo(() => {
        const start = moment
            .tz(`${providerPaymentMonth.month}-01`, timezone)
            .startOf('month');
        const end = start.clone().endOf('month');

        const today = moment.tz(timezone).startOf('day');

        const days: JSX.Element[] = ['M', 'T', 'W', 'T', 'F', 'S', 'S'].map(
            (day, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`day.${index}`} className={styles.dayHeading}>
                    <div>{day}</div>
                </li>
            ),
        );

        // pre-fill with empty array elements if day does not start with monday
        const prefillDays = ((start.day() || 7) - 1) % 7;
        days.push(
            ...Array(prefillDays)
                .fill(null)
                .map((day, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`prefill-day.${index}`} />
                )),
        );

        const currentDay = start.clone();
        while (currentDay.isBefore(end)) {
            const day = currentDay.date();
            const isToday = currentDay.isSame(today, 'day');
            const isWorkingDay = (
                providerPaymentMonth.paymentCalculation?.workingDays || []
            ).includes(day);
            const isExtraShiftDay = (
                providerPaymentMonth.extraShiftDays || []
            ).includes(day);
            const isCriticalExtraShiftDay = (
                providerPaymentMonth.criticalExtraShiftDays || []
            ).includes(day);

            const isPastOrToday =
                currentDay.isBefore(today) || currentDay.isSame(today, 'day');

            let tooltipTitle = '';
            if (isExtraShiftDay) {
                tooltipTitle = translate(
                    'vetspireExtension.providerPayment.monthOverview.extraShiftDay',
                ) as string;
            } else if (isCriticalExtraShiftDay) {
                tooltipTitle = translate(
                    'vetspireExtension.providerPayment.monthOverview.criticalExtraShiftDay',
                ) as string;
            } else if (isWorkingDay) {
                tooltipTitle = translate(
                    'vetspireExtension.providerPayment.monthOverview.workingDay',
                ) as string;
            } else {
                tooltipTitle = translate(
                    'vetspireExtension.providerPayment.monthOverview.noWorkingDay',
                ) as string;
            }

            if (isToday) {
                tooltipTitle =
                    translate(
                        'vetspireExtension.providerPayment.monthOverview.today',
                    ) + tooltipTitle;
            }

            const content = <div className={styles.dayNumber}>{day}</div>;

            days.push(
                <li
                    key={day}
                    className={classnames(styles.day, {
                        [styles.shiftDay]: isWorkingDay && isPastOrToday,
                        [styles.extraShiftDay]:
                            isExtraShiftDay && isPastOrToday,
                        [styles.criticalExtraShiftDay]:
                            isCriticalExtraShiftDay && isPastOrToday,
                        [styles.noShiftDay]:
                            !isWorkingDay &&
                            !isExtraShiftDay &&
                            !isCriticalExtraShiftDay &&
                            isPastOrToday,
                        [styles.futureExtraShiftDay]:
                            isExtraShiftDay && !isPastOrToday,
                        [styles.futureCriticalExtraShiftDay]:
                            isCriticalExtraShiftDay && !isPastOrToday,
                        [styles.futureNoShiftDay]:
                            !isWorkingDay &&
                            !isExtraShiftDay &&
                            !isCriticalExtraShiftDay &&
                            !isPastOrToday,
                    })}
                >
                    {tooltipTitle ? (
                        <LightTooltip
                            arrow
                            title={
                                <div className={styles.tooltipContent}>
                                    {tooltipTitle}
                                </div>
                            }
                            placement="top"
                        >
                            {content}
                        </LightTooltip>
                    ) : (
                        content
                    )}
                    {isToday && <div className={styles.today} />}
                </li>,
            );
            currentDay.add({ days: 1 });
        }

        return (
            <div className={styles.listContainer}>
                <div className={styles.title}>
                    {translate(
                        'vetspireExtension.providerPayment.monthOverview.yourShifts',
                    )}
                </div>
                <ul className={styles.daysList}>{days}</ul>
            </div>
        );
    }, [providerPaymentMonth, translate, timezone]);

    const renderedProgress = React.useMemo(() => {
        const monthlyGoal =
            providerPaymentMonth.paymentCalculation?.monthlyGoal || 0;

        const totalProduction =
            providerPaymentMonth.paymentCalculation?.totalProduction || 0;

        if (monthlyGoal <= 0) {
            return null;
        }

        const progress = Math.min(totalProduction / (monthlyGoal / 100), 100);

        if (progress === 100) {
            return (
                <>
                    <div className={styles.title}>
                        {translate(
                            'vetspireExtension.providerPayment.monthOverview.monthlyGoal',
                        )}
                    </div>
                    <div className={styles.progressGoalReached}>
                        <div>
                            {translate(
                                'vetspireExtension.providerPayment.monthOverview.goalReached',
                                {
                                    name: provider?.firstName || '',
                                },
                            )}
                        </div>
                    </div>
                </>
            );
        }

        return (
            <>
                <div className={styles.title}>
                    {translate(
                        'vetspireExtension.providerPayment.monthOverview.monthlyGoal',
                    )}
                </div>
                <div className={styles.progress}>
                    <div
                        className={styles.progressBar}
                        style={{ width: `${progress}%` }}
                    />
                    <div
                        className={classnames(styles.progressTextRight, {
                            [styles.lightColor]: progress > 80,
                        })}
                    >
                        {moneyFormatter.format(monthlyGoal)}
                    </div>
                    <div className={styles.progressTextLeft}>
                        {moneyFormatter.format(totalProduction)}
                    </div>
                </div>
            </>
        );
    }, [providerPaymentMonth, provider, translate, moneyFormatter]);

    return (
        <div className={styles.container}>
            {renderedDays}
            {renderedProgress}
        </div>
    );
};

export default MonthOverview;
