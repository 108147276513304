import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import vetspireLink from './vetspireLink';
// eslint-disable-next-line import/no-cycle
import bondLink from './bondLink';

function errorHandler(error: ErrorResponse) {
    if (Array.isArray(error.graphQLErrors)) {
        console.error(
            error.operation.operationName,
            'graphQLError',
            error.graphQLErrors
                .map(
                    (graphQLError) =>
                        graphQLError?.message || 'unknown GraphQL error',
                )
                .join(', '),
            true,
        );
    }

    if (error.networkError) {
        console.error(
            error.operation.operationName,
            'networkError',
            error.networkError.message || 'unknown network error',
            true,
        );
    }

    if (!Array.isArray(error.graphQLErrors) && !error.networkError) {
        console.error(
            error.operation.operationName,
            'unknownError',
            JSON.stringify(error),
            true,
        );
    }
}

const apiClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
        onError(errorHandler),
        ApolloLink.split(
            (operation) =>
                !operation.getContext().clientName ||
                operation.getContext().clientName === 'vetspire',
            vetspireLink,
            bondLink,
        ),
    ]),
});

export default apiClient;
