import * as React from 'react';
import { gql } from '@apollo/client';
import type { PhoneNumber } from '@bondvet/types/generated/vetspire';
import { CircularProgress } from '@mui/material';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import useModalHeightResizer from 'hooks/useModalHeightResizer';
import useVetspireQuery from 'hooks/useVetspireQuery';
import { closeCustomModal, refreshPage } from 'lib/vetspireActions';
import styles from './SmsConversationalOptIn.module.scss';
import OptInModal, {
    type Client,
} from '../Texting/components/SmsConversationalOptIn';

const clientDataQuery = gql`
    query clientDataQuery($clientId: ID!) {
        client(id: $clientId) {
            id
            givenName
            familyName
            preferredPhoneNumber {
                value
            }
        }
    }
`;

type ApiClient = Omit<Client, 'preferredPhoneNumber'> & {
    id: string;
    preferredPhoneNumber: null | Pick<PhoneNumber, 'value'>;
};

type ClientDataQueryResult = {
    client: null | ApiClient;
};

type ClientDataQueryVariables = {
    clientId: string;
};

export default function SmsConversationalOptIn() {
    const { search } = useLocation();
    const root = React.useRef<HTMLDivElement>(null);

    useModalHeightResizer(root, 'smsConversationalOptIn');

    const { clientId } = React.useMemo(() => {
        const urlParams = new URLSearchParams(search);

        return {
            clientId: urlParams.get('client_id'),
        };
    }, [search]);

    const { data, loading } = useVetspireQuery<
        ClientDataQueryResult,
        ClientDataQueryVariables
    >(clientDataQuery, {
        skip: !clientId || clientId === '',
        variables: { clientId: clientId as string },
        fetchPolicy: 'cache-and-network',
    });

    const client = React.useMemo<null | Client>(() => {
        if (data?.client) {
            const { preferredPhoneNumber, ...rest } = data.client;

            return {
                ...rest,
                preferredPhoneNumber: preferredPhoneNumber?.value ?? '',
            };
        }

        return null;
    }, [data?.client]);

    const onClose = React.useCallback(() => {
        closeCustomModal('smsConversationalOptIn');
    }, []);

    const onSubmit = React.useCallback(async () => {
        window.setTimeout(() => {
            refreshPage();
            onClose();
        }, 100);
    }, [onClose]);

    return (
        <div
            className={classnames(styles.root, {
                [styles.loading]: loading,
            })}
            ref={root}
        >
            {loading && <CircularProgress />}
            {!loading && client && (
                <OptInModal
                    onClose={onClose}
                    client={client}
                    onSubmit={onSubmit}
                />
            )}
        </div>
    );
}
