export const EMPLOYEES_DATABASE_PATH = 'website/data/employees';
export const CLINIC_TEAM_DATABASE_PATH = 'website/data/clinicTeam';

export const EMPLOYEE_IMAGES_STORAGE_PATH = 'employees/uploads';
export const CLINIC_TEAM_IMAGES_STORAGE_PATH = 'clinicTeam/uploads';

export const CLINIC_TEAM_ROLES = [
    'Veterinarian',
    'Specialty Surgeon',
    'Associate Medical Director',
    'Medical Director',
    'Associate Regional Medical Director',
    'Regional Medical Director',
    'Associate Clinic Manager',
    'Clinic Manager',
    'Associate Regional Clinic Manager',
    'Regional Clinic Manager',
] as const;

export type ClinicTeamRole = (typeof CLINIC_TEAM_ROLES)[number];
