import * as React from 'react';

import classnames from 'classnames';

import { useTranslate } from '@bondvet/web-app-i18n/util';
import { ClientDigitalWalkIn } from '@bondvet/types/digitalWalkIns';

import { openPatientChart } from 'lib/vetspireActions';

import { ReactComponent as ClientSVG } from '../../assets/client.svg';
import { ReactComponent as PatientSVG } from '../../assets/patient.svg';
import { ReactComponent as ReasonSVG } from '../../assets/reason.svg';

import styles from './WalkInDetails.module.scss';

type WalkInDetailsProps = {
    walkInData: ClientDigitalWalkIn;
};

export default function WalkInDetails({
    walkInData,
}: WalkInDetailsProps): React.ReactElement {
    const translate = useTranslate();
    const {
        firstName,
        lastName,
        pet,
        phone,
        vetspireClientId,
        vetspirePatientId,
        reasonName,
        notes,
    } = walkInData;

    const formattedClientName = `${firstName} ${lastName}`;

    return (
        <div className={styles.root}>
            <div className={styles.infoGroup}>
                <ClientSVG />
                <div className={styles.infoSection}>
                    <div className={classnames(styles.text, styles.header)}>
                        {formattedClientName}
                    </div>
                    <div className={styles.text}>{phone}</div>
                </div>
            </div>
            <div className={styles.infoGroup}>
                <PatientSVG />
                <div className={styles.petNameAndLink}>
                    <div className={classnames(styles.text, styles.header)}>
                        {pet}
                    </div>
                    <span
                        className={styles.patientLink}
                        onClick={() =>
                            openPatientChart(
                                vetspireClientId || '',
                                vetspirePatientId || '',
                            )
                        }
                    >
                        {translate(
                            'vetspireExtension.digitalWalkIns.walkIns.patientChart',
                        )}
                    </span>
                </div>
            </div>
            <div className={styles.infoGroup}>
                <ReasonSVG />
                <div className={styles.infoSection}>
                    <div className={classnames(styles.text, styles.header)}>
                        {reasonName}
                    </div>
                    <div className={styles.text}>{notes}</div>
                </div>
            </div>
        </div>
    );
}
