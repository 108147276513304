import type { VetspireSetting } from '@bondvet/types/vetspire';
import {
    MESSAGE_SOURCE_FLYOUT,
    MESSAGE_SOURCE_NAVIGATION,
    MESSAGE_TARGET_CONTENT,
    MESSAGE_TARGET_TWEAKS,
    MESSAGE_TARGET_NAVIGATION,
    sendBackgroundMessage,
} from 'lib/backgroundMessages';
import type { BackgroundMessage } from 'lib/backgroundMessages';
import { sleep } from 'lib/utils';
import type { SetSukiModeData, SukiMode } from 'pages/Suki/types';
// eslint-disable-next-line import/no-relative-packages
import type { Toast } from '../../../shared/types';

export type VetspireExtensionMessage = BackgroundMessage & {
    pathName?: string;
    hostName?: string;
    host?: string;
    protocol?: string;
    locationId?: string;
    vetspireAuthToken?: string;
    vetspireSettings?: VetspireSetting;
};

export enum Page {
    tasks = 'tasks',
    surgeryForms = 'surgery-forms',
    surgeryReferrals = 'surgery-referrals',
    encounters = 'encounters',
    clientDetails = 'client',
    bonusly = 'bonusly',
    staffOptimizationUKG = 'staff-optimization-ukg',
    providerPayment = 'provider-payment',
    apptLog = 'appt-log',
    infoPDF = 'info-pdf',
    externalReferrals = 'external-referrals',
    surgeryCalendar = 'surgery-calendar',
    medicalMishaps = 'medical-mishaps',
    telehealth = 'telehealth',
    pulseMonthlyReportFrame = 'pulse-monthly-report-frame',
    pulseMonthlyReport = 'pulse-monthly-report',
    suki = 'suki',
    texting = 'texting',
    patientPlans = 'patient-plans',
    patientPlanSignUp = 'patient-plan-sign-up',
    travelCertificates = 'travel-certificates',
    intradayCapacity = 'intraday-capacity',
    questionnaire = 'questionnaire',
    digitalWalkIns = 'digital-walk-ins',
}

export const ModalPages = {
    smsConversationalOptIn: 'sms-conversational-opt-in',
    intradayCapacityModal: 'intraday-capacity-modal',
} as const;

export type ModalPage = keyof typeof ModalPages;

type PageConfig = {
    [key in Page]: {
        width: string;
        transparent?: boolean;
    };
};

type ModalPageConfig = Record<keyof typeof ModalPages, { className?: string }>;

const pageConfig: PageConfig = {
    [Page.tasks]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.surgeryForms]: {
        width: 'calc(100% - var(--vs-ext-navigation-bar-size))',
        transparent: true,
    },
    [Page.surgeryReferrals]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.encounters]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.clientDetails]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.telehealth]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.staffOptimizationUKG]: {
        width: 'calc(100% - var(--vs-ext-navigation-bar-size))',
        transparent: false,
    },
    [Page.providerPayment]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.apptLog]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.bonusly]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.infoPDF]: {
        width: 'calc(100% - var(--vs-ext-navigation-bar-size))',
        transparent: true,
    },
    [Page.externalReferrals]: {
        width: 'calc(100% - var(--vs-ext-navigation-bar-size))',
        transparent: true,
    },
    [Page.surgeryCalendar]: {
        width: 'calc(100% - var(--vs-ext-navigation-bar-size))',
        transparent: false,
    },
    [Page.medicalMishaps]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.pulseMonthlyReportFrame]: {
        width: 'calc(100% - var(--vs-ext-navigation-bar-size))',
        transparent: true,
    },
    [Page.pulseMonthlyReport]: {
        width: '100%',
        transparent: false,
    },
    [Page.suki]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.texting]: {
        width: 'calc(85% - var(--vs-ext-navigation-bar-size))',
        transparent: false,
    },
    [Page.patientPlans]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.patientPlanSignUp]: {
        width: 'calc(100% - var(--vs-ext-navigation-bar-size))',
        transparent: false,
    },
    [Page.travelCertificates]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.intradayCapacity]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.questionnaire]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
    [Page.digitalWalkIns]: {
        width: 'var(--vs-ext-flyout-default-width)',
        transparent: false,
    },
};

const modalPageConfig: ModalPageConfig = {
    smsConversationalOptIn: {
        className: 'bb-sms-conversational-opt-in',
    },
    intradayCapacityModal: {
        className: 'bb-expanded-access-slots__modal',
    },
};

function sendMessage(action: string, data?: Record<string, unknown>): void {
    sendBackgroundMessage(
        MESSAGE_SOURCE_NAVIGATION,
        [MESSAGE_TARGET_CONTENT],
        action,
        data,
    );
}

export function openFlyout(page: Page, parameters = ''): void {
    sendMessage('openFlyout', {
        page: page + parameters,
        width: pageConfig[page].width,
        transparent: pageConfig[page].transparent,
    });
}

export function openCustomModal(
    modalPage: ModalPage,
    parameters: string | URLSearchParams = '',
): void {
    let url = ModalPages[modalPage] as string;

    if (parameters !== '') {
        url = `${url}?${parameters.toString()}`;
    }

    sendMessage('openCustomModal', {
        url,
        className: modalPageConfig[modalPage].className,
    });
}

export function closeCustomModal(modalPage: ModalPage): void {
    sendMessage('closeCustomModal', {
        className: modalPageConfig[modalPage].className,
    });
}

export function resizeCustomModal(
    modalPage: ModalPage,
    newHeight: number,
): void {
    sendMessage('resizeCustomModal', {
        className: modalPageConfig[modalPage].className,
        newHeight,
    });
}

export function setSukiMode(sukiMode: SukiMode): void {
    sendBackgroundMessage(
        MESSAGE_SOURCE_FLYOUT,
        [MESSAGE_TARGET_NAVIGATION],
        'setSukiMode',
        { sukiMode } satisfies SetSukiModeData,
    );

    sendMessage('setSukiMode', {
        sukiMode,
    } satisfies SetSukiModeData);
}

export function navigateToVetspireDay(
    clinic: string | undefined,
    day: string | undefined,
    andCloseFlyout = true,
): void {
    if (clinic != null && day != null) {
        sendMessage('navigateToVetspireDay', {
            clinic,
            day,
            closeFlyout: andCloseFlyout,
        });
    }
}

export function closeFlyout(): void {
    sendMessage('closeFlyout');
}

export function extendMenuBar(moveFlyOut: boolean): void {
    sendMessage('extendMenuBar', { moveFlyOut });
}

export function minimizeMenuBar(): void {
    sendMessage('minimizeMenuBar');
}

export function showButtonTooltip(top: number, title: string): void {
    sendMessage('showButtonTooltip', { top, title });
}

export function hideButtonTooltip(title: string): void {
    sendMessage('hideButtonTooltip', { title });
}

export async function ignoreAlerts(
    ignoreMessages: readonly string[],
    timeoutMs = 1000,
) {
    const delayMs = 50;

    sendBackgroundMessage(
        MESSAGE_SOURCE_NAVIGATION,
        [MESSAGE_TARGET_TWEAKS],
        'ignoreAlerts',
        { ignoreMessages, timeoutMs: timeoutMs + delayMs },
    );

    // give Vetspire some time to process the message
    await sleep(delayMs);
}

export function closeModal(): void {
    sendMessage('closeModal');
}

export function openToast(toast: Toast): void {
    sendMessage('openToast', toast as unknown as Record<string, unknown>);
}

export function closeAllToasts(category?: string): void {
    sendMessage('closeAllToasts', { category });
}

export function refreshPage(): void {
    sendMessage('refreshPage');
}

export function sendDigitalWalkInsDataToSchedule(
    totalWalkIns: number | null,
): void {
    sendBackgroundMessage(
        MESSAGE_SOURCE_NAVIGATION,
        [MESSAGE_TARGET_TWEAKS],
        'setDigitalWalkIns',
        { totalWalkIns },
    );
}

export function openVetspirePage(url: string): void {
    sendMessage('openVetspirePage', { url });
}

export function openPatientChart(clientId: string, patientId: string): void {
    openVetspirePage(`/clients/${clientId}/patients/${patientId}/chart`);
}

export function openPatientTasks(clientId: string, patientId: string): void {
    openVetspirePage(`/clients/${clientId}/patients/${patientId}/tasks`);
}

export function openPatientPlans(clientId: string, patientId: string): void {
    openVetspirePage(`/clients/${clientId}/patients/${patientId}/plans`);
}

export function openPatientEncounter(
    clientId: string,
    patientId: string,
    encounterId: string,
): void {
    openVetspirePage(
        `/clients/${clientId}/patients/${patientId}/encounters2/${encounterId}`,
    );
}

export function openPatient(clientId: string, patientId?: string): void {
    if (patientId) {
        openVetspirePage(`/clients/${clientId}/patients/${patientId}`);
    } else {
        openVetspirePage(`/clients/${clientId}`);
    }
}

export function openInvoice(
    clientId: string,
    patientId: string,
    invoiceId: string,
): void {
    openVetspirePage(
        `/clients/${clientId}/patients/${patientId}/billing/invoices/${invoiceId}`,
    );
}

export function requestLocationId(): void {
    sendMessage('requestLocationId');
}

export function updateCalendar(): void {
    sendMessage('updateCalendar');
}

export function requestManifestData(): void {
    sendMessage('requestManifestData');
}

export function requestCurrentScheduleDate(): void {
    sendBackgroundMessage(
        MESSAGE_SOURCE_NAVIGATION,
        [MESSAGE_TARGET_TWEAKS],
        'requestCurrentScheduleDate',
    );
}
