import * as React from 'react';
import classnames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import useTranslate from 'hooks/useTranslate';

import styles from './ConfirmationDialog.module.scss';

type ConfirmationDialogProps = {
    onClose: (refresh?: boolean) => void;
    action: 'noShow' | 'markNotArrived';
    handleSubmit: () => void;
    loading: boolean;
};

const ConfirmationDialog: React.FunctionComponent<ConfirmationDialogProps> = ({
    onClose,
    handleSubmit,
    action,
    loading,
}) => {
    const translate = useTranslate();

    return (
        <Dialog open onClose={() => onClose()}>
            <DialogTitle className={styles.title}>
                {translate(
                    `vetspireExtension.digitalWalkIns.confirmation.${action}.title`,
                )}
                <IconButton
                    onClick={() => onClose()}
                    className={styles.closeIcon}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles.content}>
                <div>
                    {translate(
                        `vetspireExtension.digitalWalkIns.confirmation.${action}.question`,
                    )}
                </div>
                <div>
                    {translate(
                        `vetspireExtension.digitalWalkIns.confirmation.${action}.explanation`,
                    )}
                </div>
                <DialogActions className={styles.actionButtons}>
                    <Button
                        color="primary"
                        variant="text"
                        className={styles.cancel}
                        onClick={() => onClose()}
                    >
                        {translate(
                            `vetspireExtension.digitalWalkIns.confirmation.cancel`,
                        )}
                    </Button>
                    <Button
                        disabled={loading}
                        type="submit"
                        onClick={() => handleSubmit()}
                        className={classnames(styles.submit, {
                            [styles.disabled]: loading,
                        })}
                    >
                        {translate(
                            `vetspireExtension.digitalWalkIns.confirmation.${action}.submit`,
                        )}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmationDialog;
