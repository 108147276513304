import { gql } from '@apollo/client';

export type Provider = {
    id: string;
    username: string;
    email: string;
    isActive: boolean;
    isVeterinarian: boolean;
    firstName: string | null;
    lastName: string | null;
    name: string;
    defaultVetspireLocationId: string | null;
    vetspireLocationIds: null | readonly string[];
};

export type ProvidersQueryResult = {
    vetspireProviders: ReadonlyArray<Provider>;
};

export type ProvidersQueryVariables = {
    vetspireLocationId?: string | null;
    onlyActive: boolean;
};

export const providersQuery = gql`
    query vetspireProviders($vetspireLocationId: ID, $onlyActive: Boolean) {
        vetspireProviders(
            vetspireLocationId: $vetspireLocationId
            onlyActive: $onlyActive
        ) {
            id
            username
            email
            isActive
            isVeterinarian
            firstName
            lastName
            name
            defaultVetspireLocationId
            vetspireLocationIds
        }
    }
`;

export type Viewer = {
    id: string;
    username: string;
    email: string;
    isActive: boolean;
    isVeterinarian: boolean;
    givenName: string | null;
    familyName: string | null;
    name: string;
    providerRole: { id: string; name: string; permissions: readonly string[] };
};

export type ViewerQueryResult = {
    viewer: Viewer;
};

export const viewerQuery = gql`
    query {
        viewer {
            id
            username
            email
            isActive
            isVeterinarian
            givenName
            familyName
            name
            providerRole {
                id
                name
                permissions
            }
        }
    }
`;

export const impactScoreV2 = gql`
    query impactScoreV2($locationId: InternalId, $date: DateString) {
        impactScoreV2(
            locationId: $locationId
            date: $date
            application: "VS Extension"
        ) {
            locationId
            vetspireLocationId
            date
            visits
            visitsMilestone1
            visitsMilestone2
            visits100PercentValue
            visitsPercent
            visitsMilestone1Percent
            visitsMilestone2Percent
            revenuePerDoctor
            revenueMilestone1
            revenuePerDoctorMilestone1
            revenuePerDoctorMilestone2
            revenuePerDoctorPercent
            revenuePerDoctorMilestone1Percent
            revenuePerDoctorMilestone2Percent
            revenuePerDoctor100PercentValue
            payoutMilestone1
            payoutMilestone2
            milestone1Reached
            milestone2Reached
            payout
            payoutEmployees {
                name
            }
            payoutOfMonth
            bonusPercentMilestone1
            bonusPercentMilestone2
        }
    }
`;
