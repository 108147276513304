import { GRAPHQL_CLIENT_NAMES } from 'lib/constants';
import * as React from 'react';
import { ApolloError, useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import {
    type Provider,
    type ProvidersQueryResult,
    type ProvidersQueryVariables,
    providersQuery,
} from 'api/providers/queries';

export type UseProviders = {
    loading: boolean;
    providers: readonly Provider[];
    error?: ApolloError;
};

export type { Provider };

export default function useProviders(
    vetspireLocationId?: string,
    onlyActive = true,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
): UseProviders {
    const { data, loading, error } = useQuery<
        ProvidersQueryResult,
        ProvidersQueryVariables
    >(providersQuery, {
        context: { clientName: GRAPHQL_CLIENT_NAMES.default },
        variables: {
            vetspireLocationId,
            onlyActive,
        },
        fetchPolicy,
    });

    return React.useMemo(
        () => ({
            loading,
            providers: data?.vetspireProviders ?? [],
            error,
        }),
        [loading, data?.vetspireProviders, error],
    );
}
