import * as React from 'react';
import classnames from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';

import Button from '@mui/material/Button';

import { useTranslate } from '@bondvet/web-app-i18n/util';
import { ClientDigitalWalkIn } from '@bondvet/types/digitalWalkIns';

import useTimezone from 'hooks/useTimezone';

import { ReactComponent as CheckSVG } from '../../assets/check.svg';
import { ReactComponent as ArrivalSVG } from '../../assets/arrival.svg';

import StatusTag from '../StatusTag';

import styles from './WalkIn.module.scss';
import WalkInDetails from '../WalkInDetails';

type WalkInCardProps = {
    walkInData: ClientDigitalWalkIn;
    setArrivedModalData: (walkIn: ClientDigitalWalkIn) => void;
    handleMarkNoShow: (digitalWalkInId: string) => void;
    handleMarkNotArrivedYet: (digitalWalkInId: string) => void;
};

export default function WalkIn({
    walkInData,
    setArrivedModalData,
    handleMarkNoShow,
    handleMarkNotArrivedYet,
}: WalkInCardProps): React.ReactElement {
    const translate = useTranslate();
    const timezone = useTimezone();
    const { id, date, arrivedAt, noShowAt, hasArrived, isNoShow } = walkInData;

    const formattedTime = formatInTimeZone(date.toDate(), timezone, 'h:mm aa');
    const formattedArrivedAt =
        arrivedAt && formatInTimeZone(arrivedAt.toDate(), timezone, 'h:mm aa');
    const formattedNoShowedAt =
        noShowAt && formatInTimeZone(noShowAt.toDate(), timezone, 'h:mm aa');

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <StatusTag hasArrived={hasArrived} isNoShow={isNoShow} />
                <div
                    className={classnames(styles.arrivalInfo, {
                        [styles.notArrived]: !isNoShow && !hasArrived,
                    })}
                >
                    <ArrivalSVG />
                    <div className={styles.timeInfo}>
                        <div className={styles.text}>
                            {translate(
                                'vetspireExtension.digitalWalkIns.walkIns.expectedArrival',
                                {
                                    time: formattedTime,
                                },
                                {
                                    renderInnerHtml: true,
                                },
                            )}
                        </div>
                        {hasArrived && formattedArrivedAt && (
                            <div className={styles.text}>
                                {translate(
                                    'vetspireExtension.digitalWalkIns.walkIns.arrivedAt',
                                    {
                                        time: formattedArrivedAt,
                                    },
                                    {
                                        renderInnerHtml: true,
                                    },
                                )}
                            </div>
                        )}
                        {isNoShow && formattedNoShowedAt && (
                            <div className={styles.text}>
                                {translate(
                                    'vetspireExtension.digitalWalkIns.walkIns.noShowedAt',
                                    {
                                        time: formattedNoShowedAt,
                                    },
                                    {
                                        renderInnerHtml: true,
                                    },
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <WalkInDetails walkInData={walkInData} />
            </div>
            <div className={styles.buttons}>
                <Button
                    type="button"
                    variant="outlined"
                    className={classnames(styles.button, styles.arrived, {
                        [styles.clicked]: hasArrived,
                    })}
                    disabled={hasArrived}
                    startIcon={hasArrived && <CheckSVG />}
                    onClick={() => setArrivedModalData(walkInData)}
                >
                    {translate(
                        'vetspireExtension.digitalWalkIns.walkIns.buttons.arrived',
                    )}
                </Button>
                <Button
                    type="button"
                    variant={isNoShow ? 'outlined' : 'text'}
                    className={classnames(styles.button, styles.noShow, {
                        [styles.clicked]: isNoShow,
                    })}
                    disabled={isNoShow}
                    startIcon={isNoShow && <CheckSVG />}
                    onClick={() => handleMarkNoShow(id)}
                >
                    {walkInData.isNoShow
                        ? translate(
                              'vetspireExtension.digitalWalkIns.walkIns.buttons.isNoShow',
                          )
                        : translate(
                              'vetspireExtension.digitalWalkIns.walkIns.buttons.markNoShow',
                          )}
                </Button>

                {(walkInData.hasArrived || walkInData.isNoShow) && (
                    <Button
                        type="button"
                        variant="text"
                        className={classnames(styles.button, styles.notArrived)}
                        onClick={() => handleMarkNotArrivedYet(id)}
                    >
                        {translate(
                            'vetspireExtension.digitalWalkIns.walkIns.buttons.markNotArrived',
                        )}
                    </Button>
                )}
            </div>
        </div>
    );
}
