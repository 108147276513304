import * as React from 'react';
import classnames from 'classnames';

import { useTranslate } from '@bondvet/web-app-i18n/util';

import styles from './StatusTag.module.scss';

type StatusTagProps = {
    hasArrived: boolean;
    isNoShow: boolean;
};

export default function StatusTag({
    hasArrived,
    isNoShow,
}: StatusTagProps): React.ReactElement {
    const translate = useTranslate();

    const status: 'arrived' | 'noShow' | 'notArrivedYet' = React.useMemo(() => {
        if (!hasArrived && !isNoShow) {
            return 'notArrivedYet';
        }
        if (hasArrived) {
            return 'arrived';
        }
        return 'noShow';
    }, [hasArrived, isNoShow]);

    return (
        <div
            className={classnames(styles.root, {
                [styles.arrived]: hasArrived,
                [styles.noShow]: isNoShow,
            })}
        >
            {translate(
                `vetspireExtension.digitalWalkIns.walkIns.status.${status}`,
            )}
        </div>
    );
}
