import { gql } from '@apollo/client';

export const confirmShift = gql`
    mutation confirmShift($shiftId: ID!) {
        confirmShift(shiftId: $shiftId) {
            success
            error
        }
    }
`;

export const importSingleCreditTransaction = gql`
    mutation importSingleCreditTransaction($since: Date!, $clientId: String!) {
        importSingleCreditTransaction(since: $since, clientId: $clientId) {
            success
            error
        }
    }
`;

export const removeShift = gql`
    mutation removeShift($shiftId: ID!) {
        removeShift(shiftId: $shiftId) {
            success
            error
        }
    }
`;

export const setExtensionMenuBarItems = gql`
    mutation setExtensionMenuBarItems($userId: ID!, $menuItemList: [String]!) {
        setExtensionMenuBarItems(userId: $userId, menuItemList: $menuItemList) {
            success
            error
        }
    }
`;

export const uploadAndSendSurgeryForm = gql`
    mutation uploadAndSendSurgeryForm(
        $surgeryFormId: ID!
        $encounterId: String!
        $medicationIds: [String]
        $optionsId: String
        $note: String
    ) {
        uploadAndSendSurgeryForm(
            surgeryFormId: $surgeryFormId
            input: {
                encounterId: $encounterId
                medicationIds: $medicationIds
                optionsId: $optionsId
                note: $note
            }
        ) {
            success
            error
        }
    }
`;

export const notifyNewBioSubmissionMutation = gql`
    mutation notifyNewBioSubmission($name: String!) {
        notifyNewBioSubmission(name: $name) {
            success
            error
        }
    }
`;
