import * as React from 'react';
import moment from 'moment-timezone';
import useTranslate from 'hooks/useTranslate';
import InfoIcon from '@mui/icons-material/Info';
import useMoneyFormatter from 'hooks/useMoneyFormatter';
import Confetti from 'react-confetti';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Provider } from 'api/providers/queries';
import LightTooltip from 'components/LightTooltip';
import classnames from 'classnames';
import {
    ProviderPaymentMonth,
    ProviderRightValue,
} from '@bondvet/types/providers';
import { openFlyout, Page } from 'lib/vetspireActions';
import { useAnalytics } from 'hooks/useAnalytics';
import useViewerSettings from 'hooks/useViewerSettings';
import styles from './TotalBonus.module.scss';
import ActionButton from '../../../ActionButton';
import PaymentAdminMessage from '../PaymentAdminMessage';

interface TotalBonusProps {
    timezone: string;
    provider: Provider;
    providerPaymentMonth: ProviderPaymentMonth;
}

const TotalBonus: React.FunctionComponent<TotalBonusProps> = ({
    timezone,
    provider,
    providerPaymentMonth,
}) => {
    const translate = useTranslate();
    const analytics = useAnalytics();
    const { rights } = useViewerSettings();

    const moneyFormatter = useMoneyFormatter({
        maximumFractionDigits: 0,
    });

    const monthValue = parseInt(providerPaymentMonth.month.substring(5), 10);

    const today = moment.tz(timezone).date();

    const isCurrentMonth =
        moment.tz(timezone).format('YYYY-MM') === providerPaymentMonth.month;

    const monthlyBonus =
        providerPaymentMonth.paymentCalculation?.monthlyBonus || 0;

    const totalBonus = providerPaymentMonth.paymentCalculation?.totalBonus || 0;

    const monthlyGoal =
        providerPaymentMonth.paymentCalculation?.monthlyGoal || 0;

    const totalProduction =
        providerPaymentMonth.paymentCalculation?.totalProduction || 0;

    const goalHit = totalProduction > monthlyGoal;

    const [confettiStatus, setConfettiStatus] = React.useState<{
        month: string;
        visible: boolean;
    }>({ month: '', visible: false });
    const confettiTimerRef = React.useRef<NodeJS.Timeout | null>(null);

    React.useEffect(() => {
        if (confettiStatus.month === providerPaymentMonth.month) {
            return;
        }

        if (confettiTimerRef.current) {
            clearTimeout(confettiTimerRef.current);
        }

        if (goalHit) {
            confettiTimerRef.current = setTimeout(() => {
                setConfettiStatus({
                    month: providerPaymentMonth.month,
                    visible: false,
                });
            }, 10000);

            setConfettiStatus({
                month: providerPaymentMonth.month,
                visible: true,
            });
        } else if (confettiStatus) {
            setConfettiStatus({
                month: providerPaymentMonth.month,
                visible: false,
            });
        }
    }, [confettiStatus, goalHit, providerPaymentMonth.month]);

    const progress =
        monthlyGoal === 0
            ? 0
            : Math.min(totalProduction / (monthlyGoal / 100), 100);

    let titleTag;
    let subTitleTag;

    if (progress < 25) {
        titleTag = 'titleBelow25';
        subTitleTag = 'subTitleBelow25';
    } else if (progress < 50) {
        titleTag = 'titleBelow50';
        subTitleTag = 'subTitleBelow50';
    } else if (progress < 75) {
        titleTag = 'titleBelow75';
        subTitleTag = 'subTitleBelow75';
    } else if (progress < 100) {
        titleTag = 'titleBelow100';
        subTitleTag = 'subTitleBelow100';
    } else {
        titleTag = 'title100';
        subTitleTag = 'subTitle100';
    }

    if (progress < 100 && totalBonus > 0) {
        subTitleTag = 'subTitleESD';
    }

    const isPaymentAdmin =
        rights?.vetspireExtension_providerPayment ===
        ProviderRightValue.enabled_allRecords;

    const reportNotReadyMessage =
        moment().isSame(providerPaymentMonth.month, 'month') ||
        moment()
            .startOf('month')
            .subtract(1, 'month')
            .isSame(providerPaymentMonth.month, 'month')
            ? translate(
                  `vetspireExtension.providerPayment.totalBonus.reportNotReadyYet`,
                  {
                      date: moment(providerPaymentMonth.month).format(
                          'MMMM YYYY',
                      ),
                  },
              )
            : translate(
                  `vetspireExtension.providerPayment.totalBonus.reportNotAvailable`,
                  {
                      date: moment(providerPaymentMonth.month).format(
                          'MMMM YYYY',
                      ),
                  },
              );

    // AutoSizer respects padding, so we have to add it
    return (
        <>
            <div className={styles.container}>
                <AutoSizer>
                    {({ height, width }) =>
                        confettiStatus?.visible && (
                            <Confetti height={height + 30} width={width + 20} />
                        )
                    }
                </AutoSizer>
                <div className={styles.bonus}>
                    <span
                        className={classnames({
                            [styles.lightText]: totalBonus === 0,
                        })}
                    >
                        {moneyFormatter.format(totalBonus)}
                    </span>
                    <LightTooltip
                        arrow
                        title={
                            <div className={styles.tooltipContent}>
                                <div className={styles.tooltipTitle}>
                                    {translate(
                                        `vetspireExtension.providerPayment.totalBonus.bonusStructure`,
                                    )}
                                </div>
                                <div className={styles.tooltipValue}>
                                    {translate(
                                        'vetspireExtension.providerPayment.totalBonus.monthlyBonus',
                                    )}{' '}
                                    {moneyFormatter.format(monthlyBonus)}
                                </div>
                                {providerPaymentMonth.paymentCalculation?.extraShiftDaysCalculation
                                    ?.filter(
                                        (extraShiftDayCalculation) =>
                                            !isCurrentMonth ||
                                            extraShiftDayCalculation.extraShiftDay <=
                                                today,
                                    )
                                    .map((extraShiftDayCalculation) => (
                                        <div
                                            className={styles.tooltipValue}
                                            key={
                                                extraShiftDayCalculation.extraShiftDay
                                            }
                                        >
                                            {translate(
                                                'vetspireExtension.providerPayment.totalBonus.extraShift',
                                                {
                                                    day: `${monthValue}/${extraShiftDayCalculation.extraShiftDay}`,
                                                },
                                            )}{' '}
                                            {moneyFormatter.format(
                                                extraShiftDayCalculation.bonus,
                                            )}
                                        </div>
                                    ))}
                                {providerPaymentMonth.paymentCalculation?.criticalExtraShiftDaysCalculation
                                    ?.filter(
                                        (criticalExtraShiftDaysCalculation) =>
                                            !isCurrentMonth ||
                                            criticalExtraShiftDaysCalculation.extraShiftDay <=
                                                today,
                                    )
                                    .map(
                                        (criticalExtraShiftDaysCalculation) => (
                                            <div
                                                className={styles.tooltipValue}
                                                key={
                                                    criticalExtraShiftDaysCalculation.extraShiftDay
                                                }
                                            >
                                                {translate(
                                                    'vetspireExtension.providerPayment.totalBonus.criticalExtraShift',
                                                    {
                                                        day: `${monthValue}/${criticalExtraShiftDaysCalculation.extraShiftDay}`,
                                                    },
                                                )}{' '}
                                                {moneyFormatter.format(
                                                    criticalExtraShiftDaysCalculation.bonus,
                                                )}
                                            </div>
                                        ),
                                    )}
                            </div>
                        }
                        placement="top"
                    >
                        <InfoIcon className={styles.infoIcon} />
                    </LightTooltip>
                </div>
                <div className={styles.title}>
                    {translate(
                        `vetspireExtension.providerPayment.totalBonus.${titleTag}`,
                        {
                            name: provider?.firstName || '',
                        },
                    )}
                </div>
                <div className={styles.subTitle}>
                    {translate(
                        `vetspireExtension.providerPayment.totalBonus.${subTitleTag}`,
                        {
                            name: provider?.firstName || '',
                        },
                    )}
                </div>
                {
                    <div className={styles.download}>
                        {!isPaymentAdmin &&
                        !providerPaymentMonth?.allowReportDownload ? (
                            <LightTooltip
                                arrow
                                title={
                                    <div className={styles.tooltipContent}>
                                        <div className={styles.tooltipTitle}>
                                            {reportNotReadyMessage}
                                        </div>
                                    </div>
                                }
                                placement="top"
                            >
                                <span>
                                    <ActionButton
                                        onClick={() => {}}
                                        className={styles.openReportDisabled}
                                    >
                                        {translate(
                                            'vetspireExtension.providerPayment.totalBonus.open',
                                        )}
                                    </ActionButton>
                                </span>
                            </LightTooltip>
                        ) : (
                            <ActionButton
                                disabled={
                                    !providerPaymentMonth?.allowReportDownload &&
                                    !isPaymentAdmin
                                }
                                onClick={async () => {
                                    analytics.trackEvent(
                                        Page.providerPayment,
                                        'open_monthly_report',
                                    );
                                    await openFlyout(
                                        Page.pulseMonthlyReportFrame,
                                        `?month=${providerPaymentMonth.month}&providerId=${provider.id}`,
                                    );
                                }}
                                className={styles.openReport}
                            >
                                {translate(
                                    'vetspireExtension.providerPayment.totalBonus.open',
                                )}
                            </ActionButton>
                        )}
                    </div>
                }
                {isPaymentAdmin && (
                    <PaymentAdminMessage
                        month={moment(providerPaymentMonth.month).format(
                            'MMMM',
                        )}
                        enabled={
                            providerPaymentMonth.allowReportDownload || false
                        }
                    />
                )}
            </div>
        </>
    );
};

export default TotalBonus;
