import * as React from 'react';

import styles from './NavigationIconBadge.module.scss';

interface NavigationIconBadgeProps {
    count: number;
}

export const NavigationIconBadge = ({
    count,
}: NavigationIconBadgeProps): React.ReactElement => {
    return (
        <div className={styles.container}>
            <span className={styles.number}>{count}</span>
        </div>
    );
};
