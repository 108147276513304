import * as React from 'react';
import { useTranslate } from '@bondvet/web-app-i18n/util';
import Button from '@mui/material/Button';
import { FormControl, FormLabel } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import classnames from 'classnames';

import emptyHeadshot from 'assets/emptyHeadshot.png';
import PhotoExampleGood from 'assets/photoExampleGood.png';
import PhotoExampleBad from 'assets/photoExampleBad.jpeg';
import styles from './HeadshotUpload.module.scss';

interface ImageSize {
    width?: number;
    height?: number;
    crop?: boolean;
}

interface ImageUrls {
    download: string;
    preview: string;
}

interface HeadshotUploadProps {
    questionNumber: number;
    imageId?: string | null;
    value: File | null;
    onChange: (file: File | null) => void;
}

const NUMBER_OF_TIPS = 6;
const BASE_URL = process.env.REACT_APP_IMAGES_URL ?? '';

function createImageUrl(
    imageId: string,
    { width, height, crop }: ImageSize,
): string {
    const parts = [BASE_URL, imageId];

    if (width || height || crop) {
        parts.push(width?.toString(10) ?? '');

        if (height) {
            parts.push(height.toString(10));

            if (crop) {
                parts.push('crop');
            }
        }
    }

    return parts.join('/');
}

export default function HeadshotUpload({
    questionNumber,
    imageId,
    onChange,
}: HeadshotUploadProps) {
    const translate = useTranslate();
    const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
    const fileInputRef = React.useRef<HTMLInputElement | null>(null);

    const sizes = React.useMemo<Record<string, ImageSize>>(() => {
        return {
            preview: { width: 282, height: 282 },
            download: {},
        } as Record<string, ImageSize>;
    }, []);

    const imageUrls = React.useMemo(
        () =>
            Object.keys(sizes).reduce(
                (aggregator, size) => ({
                    ...aggregator,
                    [size]: imageId
                        ? createImageUrl(imageId, sizes[size])
                        : null,
                }),
                {},
            ) as ImageUrls,
        [imageId, sizes],
    );

    const handleOpenFiles = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }, []);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            onChange(file);

            // Generate a preview URL for the image
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
        }
    };

    return (
        <FormControl className={styles.root}>
            <FormLabel className={styles.questionLabel}>
                {translate('vetspireExtension.bioQuestionnaire.photo.label', {
                    number: questionNumber,
                })}
            </FormLabel>
            <div>
                <p className={styles.label}>
                    {translate(
                        'vetspireExtension.bioQuestionnaire.photo.tips.title',
                    )}
                </p>
                <ol className={styles.tips}>
                    {[...Array(NUMBER_OF_TIPS).keys()].map((idx) => (
                        <li key={idx}>
                            {translate(
                                `vetspireExtension.bioQuestionnaire.photo.tips.${idx + 1}`,
                                {},
                                {
                                    renderInnerHtml: true,
                                },
                            )}
                        </li>
                    ))}
                </ol>
                <p className={styles.label}>
                    {translate(
                        'vetspireExtension.bioQuestionnaire.photo.examples.title',
                    )}
                </p>
                <div className={styles.examples}>
                    <div className={classnames(styles.example, styles.good)}>
                        <div className={styles.exampleImage}>
                            <img src={PhotoExampleGood} />
                            <CheckIcon className={styles.imageIcon} />
                        </div>
                        <ul>
                            {[1, 2, 3].map((idx) => (
                                <li key={idx}>
                                    <CheckIcon />
                                    <span>
                                        {translate(
                                            `vetspireExtension.bioQuestionnaire.photo.examples.good.${idx}`,
                                        )}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={classnames(styles.example, styles.bad)}>
                        <div className={styles.exampleImage}>
                            <img src={PhotoExampleBad} />
                            <CloseIcon className={styles.imageIcon} />
                        </div>
                        <ul>
                            {[1, 2, 3].map((idx) => (
                                <li key={idx}>
                                    <CloseIcon />
                                    <span>
                                        {translate(
                                            `vetspireExtension.bioQuestionnaire.photo.examples.bad.${idx}`,
                                        )}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={styles.headshotInput}>
                <p className={styles.label}>
                    {translate(
                        'vetspireExtension.bioQuestionnaire.photo.inputLabel',
                    )}
                </p>
                <div className={styles.imageWrapper}>
                    <div>
                        {previewUrl && (
                            <img
                                src={previewUrl}
                                alt="Headshot Preview"
                                className={styles.imagePreview}
                            />
                        )}
                        {!previewUrl && imageUrls.preview && (
                            <img
                                className={styles.imagePreview}
                                alt="Headshot Preview"
                                src={imageUrls.preview}
                            />
                        )}
                        {!previewUrl && !imageUrls.preview && (
                            <img
                                className={styles.imagePreview}
                                alt="no headshot"
                                src={emptyHeadshot}
                            />
                        )}
                    </div>
                    <div>
                        <input
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            type="file"
                            accept="image/*"
                            className={styles.fileInput}
                        />
                        <Button
                            variant="outlined"
                            className={styles.uploadButton}
                            onClick={handleOpenFiles}
                        >
                            <AttachFileIcon />
                            <span>
                                {translate(
                                    'vetspireExtension.bioQuestionnaire.photo.upload',
                                )}
                            </span>
                        </Button>
                        <div>
                            {translate(
                                'vetspireExtension.bioQuestionnaire.photo.supportedFiles',
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </FormControl>
    );
}
