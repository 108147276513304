import * as React from 'react';
import NavigationButton from 'components/NavigationButton/NavigationButton';
import { Page } from 'lib/vetspireActions';
import { MenuItem } from '../../hooks/useMenuList';
import NavigationIconBadge from '../NavigationIconBadge';

type NavigationItemsNotInEditModeArgs = {
    expanded: boolean;
    currentPage?: string | null;
    onNavigationClick: (page: Page) => void;
    menuButtons: MenuItem[];
    unreadMessageCount: number;
    digitalWalkInsCount: number;
};

export const NavigationItemsNotInEditMode = ({
    menuButtons,
    expanded,
    currentPage,
    onNavigationClick,
    unreadMessageCount,
    digitalWalkInsCount,
}: NavigationItemsNotInEditModeArgs): React.ReactElement => {
    return (
        <div>
            {menuButtons.map(({ icon, titleTag, page }: MenuItem) => {
                let badge;

                if (page === Page.texting && unreadMessageCount > 0) {
                    badge = <NavigationIconBadge count={unreadMessageCount} />;
                }

                if (page === Page.digitalWalkIns && digitalWalkInsCount > 0) {
                    badge = <NavigationIconBadge count={digitalWalkInsCount} />;
                }
                return (
                    <NavigationButton
                        key={page}
                        expanded={expanded}
                        titleTag={titleTag}
                        icon={icon}
                        active={currentPage === page}
                        onClick={() => onNavigationClick(page)}
                        badge={badge}
                    />
                );
            })}
        </div>
    );
};

export default NavigationItemsNotInEditMode;
